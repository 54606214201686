export const DefaultVideoAttributesMap = {
  autoplay: 'autoplay',
  crossorigin: 'crossorigin',
  loop: 'loop',
  muted: 'muted',
  playsinline: 'playsinline',
  src: 'src',
  poster: 'poster',
  preload: 'preload',
};

export const DefaultVideoEventsMap = {
  abort: 'abort',
  canplay: 'canplay',
  canplaythrough: 'canplaythrough',
  durationchange: 'durationchange',
  emptied: 'emptied',
  ended: 'ended',
  error: 'error',
  loadeddata: 'loadeddata',
  loadedmetadata: 'loadedmetadata',
  loadstart: 'loadstart',
  pause: 'pause',
  play: 'play',
  playing: 'playing',
  progress: 'progress',
  ratechange: 'ratechange',
  seeked: 'seeked',
  seeking: 'seeking',
  stalled: 'stalled',
  suspend: 'suspend',
  timeupdate: 'timeupdate',
  volumechange: 'volumechange',
  waiting: 'waiting',
};
